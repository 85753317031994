<template>
  <div id="transportDetial" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <!-- <div class="N-Page T-size-36">{{ mode }} Transportation</div> -->
        <div class="N-Page T-size-36">Transportation ({{ idtransport }})</div>
      </div>
    </div>
    <div id="transportDetail" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Transportation Detail</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S1">
              <v-text-field class="hideMessage" v-model="ref_id" label="Ref No." disabled></v-text-field>
            </div>
            <div class="box-S1">
              <v-select :disabled="readonly_shore ||
                readonly_eng ||
                readonly_pilot ||
                readonly_flight ||
                readonly_transport
                " :items="itemsListCustomer" v-model="customer" label="Customer" item-text="o_name" item-value="o_id"
                return-object></v-select>
            </div>
            <div class="box-S1">
              <v-menu v-model="menuselectdate" :close-on-content-click="false" :nudge-right="0"
                transition="scale-transition" offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field :disabled="readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_flight
                    " class="hideMessage input-date" v-model="date" label="Select Date" readonly v-on="on"
                    append-icon="mdi-calendar-range"></v-text-field>
                </template>
                <v-date-picker :disabled="readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_flight
                  " v-model="date" @input="menuselectdate = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="box-S1">
              <v-text-field :disabled="readonly_shore ||
                readonly_eng ||
                readonly_pilot ||
                readonly_flight
                " append-icon="mdi-clock-outline" v-model="time" label="Time" type="time"></v-text-field>
            </div>
          </div>
          <!-- <div class="b-row">
            <div class="box-S1">
              <v-select
                :disabled="userdata.r_id != 8"
                :items="itemsListVehicle"
                v-model="vehicleOut"
                label="Vehicle Outbound"
                item-text="v_license_plate"
                item-value="v_id"
                return-object
              ></v-select>
            </div>
            <div class="box-S1">
              <v-select
                :disabled="userdata.r_id != 8"
                :items="itemsListDriver"
                v-model="driverOut"
                label="Driver Outbound"
                item-text="d_name"
                item-value="d_id"
                return-object
              ></v-select>
            </div>

            <div class="box-S1">
              <v-select
                :disabled="userdata.r_id != 8"
                :items="itemsListVehicle"
                v-model="vehicleIn"
                label="Vehicle Inbound"
                item-text="v_license_plate"
                item-value="v_id"
                return-object
              ></v-select>
            </div>
            <div class="box-S1">
              <v-select
                :disabled="userdata.r_id != 8"
                :items="itemsListDriver"
                v-model="driverIn"
                label="Driver Inbound"
                item-text="d_name"
                item-value="d_id"
                return-object
              ></v-select>
            </div>
          </div> -->
        </div>
      </div>
      <div class="line"></div>
    </div>
    <CompAddPassengerOut2 ref="CompAddPassengerOut2" :customer="customerPassenger.o_name" :transportId="$route.params.id"
      :customer_id="customerPassenger.o_id" :b_id="this.$route.params.b_id"></CompAddPassengerOut2>
    <CompAddPassengerIn2 ref="CompAddPassengerIn2" :customer="customerPassenger.o_name" :transportId="$route.params.id"
      :customer_id="customerPassenger.o_id" :b_id="this.$route.params.b_id"></CompAddPassengerIn2>

    <Vantrac ref="Vantrac" :TransportId="$route.params.id"></Vantrac>
    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn v-show="userdata.r_id == 2 || userdata.r_id == 8" class="theme-btn-even btn-cancle Bsize150"
            @click="GotoListTransport()">
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <v-btn v-show="userdata.r_id == 2 || userdata.r_id == 8" class="theme-btn-even btn-save Bsize150"
            @click="dialogSave = true">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <div class="b-btnDelete" v-show="mode == 'Edit'">
          <v-btn v-show="userdata.r_id == 2 || userdata.r_id == 8" class="theme-btn-even btn-delete Bsize150"
            @click="dialogDelete = true">
            <span class="T-size-18">delete transport</span>
          </v-btn>
        </div>
      </div>

      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn v-show="userdata.r_id == 2 || userdata.r_id == 4 || userdata.r_id == 8
            " class="theme-btn-even btn-report Bsize150" @click="GotoReport()">
            <span class="T-size-18">Report</span>
          </v-btn>
          <v-btn v-show="userdata.r_id == 4 || userdata.r_id == 8" class="theme-btn-even btn-report Bsize150"
            @click="sentEmail()">
            <span class="T-size-18">Send Email</span>
          </v-btn>
        </div>
      </div>

      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn v-show="userdata.r_id == 8" class="theme-btn-even btn-report center" text :disabled="!(
              buttonAccept == false &&
              userdata.r_id == 8 &&
              itemsDataTransport.ts_accept_transport_by == null

            )
            " @click="AcceptTransport()">
            <span class="T-size-18">Accept Transport And Send Notification</span>
          </v-btn>
        </div>
      </div>
    </div>

    <div id="partSave" class="wrap-Main" v-show="userdata.r_id == 2 || userdata.r_id == 8"></div>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="GotoListTransport()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
    <!-- dialog สำหรับ เพิ่ม แก้ไข -->

    <v-dialog v-model="dialogSave" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSave = false" :disabled="buttonCancel">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-if="mode == 'Create'" text @click="CreateTransport()"
              :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" v-else text @click="SaveEditToDB()" :disabled="buttonSave">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Complete</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SaveCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog delete">
            <div class="t-title">Confirm Deletion</div>
            <div class="t-des">
              Are you sure you want to permanently delete this item?
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogDelete = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-delete Bsize100" text @click="DeleteTransport()">
              <span class="T-size-18">Delete</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogSendNotificationComplete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Send Notification Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SendNotificationCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog Sent Email -->
    <v-dialog v-model="dialogSentEmail" persistent max-width="400">
      <v-card id="dialog" class="TF type3">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Email</div>
            <div class="b-form">
              <v-text-field class="hideMessage" label="Email" v-model="email" return-object></v-text-field>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSentEmail = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="confirmSentEmail(email)">
              <span class="T-size-18">send</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientVan from "../plugins/feathers-client";

import CompAddPassengerOut2 from "../components/transport/CompAddPassengerOut2";
import CompAddPassengerIn2 from "../components/transport/CompAddPassengerIn2";
import Vantrac from "../components/Vantrac";
import { format } from "date-fns";
import { LogoService } from "../utils/LogoService";
import addHours from "date-fns/addHours";

export default {
  data: () => ({
    transportDate: null,
    //Add
    email: "",
    dialogSentEmail: false,
    buttonCancel: false,
    buttonSave: false,
    buttonAccept: false,

    customer: "",
    itemsListCustomer: [],

    userdata: "",
    menuselectdate: false,
    menuselecttime: false,
    dialogEvent: false,
    dialogSave: false,
    dialogConfirm: false,
    loading: false,
    dialogDelete: false,
    date: "",
    time: "",
    mode: "",
    itemsDataTransport: [],
    itemsDataBooking: [],
    idtransport: "",
    b_id: "",
    ref_id: "",
    c_name: "",

    customerPassenger: "",

    // vehicleOut: "",
    // vehicleIn: "",
    // itemsListVehicle: [],

    // driverOut: "",
    // driverIn: "",
    itemsListDriver: [],

    dialogSendNotificationComplete: false,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_transport: false,

    sumPassengerOut: 0,
    sumPassengerIn: 0,
  }),
  props: [],
  components: {
    // CompAddPassengerOut,
    // CompAddPassengerIn,
    CompAddPassengerOut2,
    CompAddPassengerIn2,
    Vantrac,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.enable_disable();
    this.mode = await this.$route.params.mode;
    this.b_id = await this.$route.params.id;
    //Add
    let dateTime = new Date().toISOString();

    this.date = dateTime.substr(0, 10);
    this.time = dateTime.substr(11, 8);

    await this.SelectListCustomer();
    await this.SelectListVehicle();
    await this.SelectListDriver();
    this.RenderUI();
  },
  methods: {
    async RenderUI() {
      this.buttonCancel = false;
      this.buttonSave = false;

      if (this.mode == "Edit") {
        this.idtransport = await this.$route.params.id;
        await this.DataTransportFromDB();
        this.DataPassengerFromDB();
      } else {
        if (this.b_id != "null") {
          await this.DataBookingFromDB();
          this.DataPassengerFromBookingDB();
        }
      }
    },
    //Add
    async SelectListCustomer() {
      try {
        var q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.customer = this.itemsListCustomer[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListVehicle() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan.service("vehicle").find({ query: q });
        this.itemsListVehicle = res.data;
        this.vehicle = this.itemsListVehicle[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListDriver() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan.service("driver").find({ query: q });
        this.itemsListDriver = res.data;
        this.driver = this.itemsListDriver[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async DataBookingFromDB() {
      try {
        var q = {};
        q.b_id = this.b_id;
        var res = await feathersClientUOA.service("booking").find({ query: q });
        this.itemsDataBooking = res.data[0];
        this.ref_id = this.itemsDataBooking.ref_id;
        this.c_name = this.itemsDataBooking.c_id;
        this.date = this.itemsDataBooking.b_departure_date.substring(0, 10);
        this.time = this.itemsDataBooking.b_departure_date.substring(11, 16);
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async CreateTransport() {
      //Add
      this.buttonCancel = true;
      this.buttonSave = true;

      if (this.b_id == "null") {
        this.CreateTransportWithoutBookingId();
      } else {
        try {
          var DataAdd = {
            b_id: this.b_id,
            ts_date: this.date + " " + this.time,
            ref_id: this.ref_id,
            o_id: this.customer.o_id,
          };
          var res = await feathersClientUOA
            .service("transport")
            .create(DataAdd);
          this.CreateTransportJob(res.ts_id);
          this.dialogSave = false;
          this.dialogConfirm = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async CreateTransportWithoutBookingId() {
      try {
        var DataAdd = {
          ts_date: this.date + " " + this.time,
          ref_id: this.ref_id,
          o_id: this.customer.o_id,

          //Add
          v_id: this.vehicle.v_id,
          v_license_plate: this.vehicle.v_license_plate,
          d_id: this.driver.d_id,
          d_name: this.driver.d_name,
        };
        var res = await feathersClientUOA.service("transport").create(DataAdd);
        this.CreateTransportJob(res.ts_id);
        this.dialogSave = false;
        this.dialogConfirm = true;
      } catch (error) {
        console.log(error);
      }
    },
    async CreateTransportJob(DataTransport) {
      try {
        await this.$refs.CompAddPassengerOut2.CreateTransportJobRawData(
          DataTransport
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await this.$refs.CompAddPassengerIn2.CreateTransportJobRawData(
          DataTransport
        );
      } catch (error) {
        console.log(error);
      }
    },
    async DataTransportFromDB() {
      try {
        var q = {};
        q.ts_id = this.idtransport;
        q.$eager = "[customer]";
        var res = await feathersClientUOA
          .service("transport")
          .find({ query: q });
        this.itemsDataTransport = res.data[0];
        this.customerPassenger = this.itemsDataTransport.customer[0];
        this.customer = {
          o_id: this.customerPassenger.o_id,
          o_name: this.customerPassenger.o_name,
        };
        this.date = this.itemsDataTransport.ts_date.substring(0, 10);
        this.time = this.itemsDataTransport.ts_date.substring(11, 16);
        this.ref_id = this.itemsDataTransport.ref_id;

        this.transportDate = format(
          new Date(this.itemsDataTransport.ts_date),
          "dd/MM/yyyy"
        );

        // this.vehicleOut = {
        //   v_id: this.itemsDataTransport.v_id_out,
        //   v_license_plate: this.itemsDataTransport.v_license_plate_out,
        // };
        // this.driverOut = {
        //   d_id: this.itemsDataTransport.d_id_out,
        //   d_name: this.itemsDataTransport.d_name_out,
        // };

        // this.vehicleIn = {
        //   v_id: this.itemsDataTransport.v_id_in,
        //   v_license_plate: this.itemsDataTransport.v_license_plate_in,
        // };
        // this.driverIn = {
        //   d_id: this.itemsDataTransport.d_id_in,
        //   d_name: this.itemsDataTransport.d_name_in,
        // };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async DataPassengerFromBookingDB() {
      try {
        await this.$refs.CompAddPassengerOut2.DataPassengerFromBookingDB(
          this.b_id
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await this.$refs.CompAddPassengerIn2.DataPassengerFromBookingDB(
          this.b_id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async DataPassengerFromDB() {
      try {
        this.sumPassengerOut =
          await this.$refs.CompAddPassengerOut2.DataPassengerFromDB(
            this.idtransport
          );
      } catch (error) {
        console.log(error);
      }
      try {
        this.sumPassengerIn =
          await this.$refs.CompAddPassengerIn2.DataPassengerFromDB(
            this.idtransport
          );
      } catch (error) {
        console.log(error);
      }
    },
    async SaveEditToDB() {
      try {
        //Add
        this.buttonCancel = true;
        this.buttonSave = true;

        var DataAdd = {
          ts_date: this.date + " " + this.time,
        };
        var res = await feathersClientUOA.service("transport").patch(this.idtransport, DataAdd);
        this.dialogSave = false;
        this.dialogConfirm = true;
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.$route.params.b_id),
          ts_id: parseInt(this.idtransport),
          ts_date: DataAdd.ts_date
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ts_id={ts_id}, ts_date={ts_date}', { action: "EditTransport", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("EditTransport, Error : {error}", { error });
        console.log("EditTransport, Error : {error}" + error);
      }
    },
    async DeleteTransport() {
      try {
        var DataDelete = {
          retire: 1,
        };
        var res = await feathersClientUOA.service("transport").patch(this.idtransport, DataDelete);
        //SEQ LOG
        let properties = {
          b_id: parseInt(this.$route.params.b_id),
          ts_id: parseInt(this.idtransport),
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ts_id={ts_id}', { action: "DeleteTransport", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("DeleteTransport, Error : {error}", { error });
        console.log("DeleteTransport, Error : {error}" + error);
      }
      this.dialogDelete = false;
      this.$router.push("/transport");
    },
    async GotoListTransport() {
      this.$router.push("/transport");
    },
    async SaveCompleted() {
      this.dialogConfirm = false;
      this.RenderUI();
    },
    async SendNotificationCompleted() {
      this.dialogSendNotificationComplete = false;
      this.RenderUI();
    },
    async AcceptTransport() {
      try {
        this.buttonAccept = true;
        var dataEdit = {
          ts_accept_transport: true,
          ts_accept_by: this.userdata.FullName,
          ts_accept_date: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          mb: this.userdata.FullName,
          md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        };
        var res = await feathersClientUOA.service("transport").patch(this.idtransport, dataEdit);
        this.$Notification.FlightAcceptTransport(this.idtransport);
        this.dialogSendNotificationComplete = true;
        this.buttonAccept = false;

        //SEQ LOG
        let properties = { 
          b_id: parseInt(this.$route.params.b_id),
          ts_id: parseInt(this.idtransport),
          ts_accept_transport:dataEdit.ts_accept_transport,
          ts_accept_by:dataEdit.ts_accept_by,
          ts_accept_date:dataEdit.ts_accept_date
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ts_id={ts_id}, ts_accept_by={ts_accept_by} ts_accept_date={ts_accept_date}', { action : "AcceptTransportAndSendNotification", username: this.userdata.u_username, role:this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("AcceptTransportAndSendNotification, Error : {error}", {error});
        console.log("AcceptTransportAndSendNotification, Error : {error}" + error);
      }
    },

    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = false;
      } else if (this.userdata.r_id == 8) {
        this.readonly_transport = true;
      }
    },

    sentEmail() {
      this.email = "";
      this.dialogSentEmail = true;
    },

    closeDialogSentEmail() {
      this.dialogSentEmail = false;
    },

    async confirmSentEmail(email) {
      try {
        this.dialogSentEmail = false;
        let data = {
          emails: email,
          transportId: this.idtransport,
        };
        var res = await feathersClientUOA.service("emailcommand").patch(this.idtransport, data);
         //SEQ LOG
         let properties = { 
          b_id: parseInt(this.$route.params.b_id),
          ts_id: parseInt(this.idtransport),
          email:email,
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ts_id={ts_id}', { action : "SendEmailForTransport", username: this.userdata.u_username, role:this.userdata.r_name, ...properties });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("SendEmailForTransport, Error : {error}", {error});
        console.log("SendEmailForTransport, Error : {error}" + error);
      }
    },

    async GotoReport() {
      var uoaimage = await LogoService.GetLogoBaseurl64(1);
      var customerlogo = await LogoService.GetLogoBaseurl64(this.customer.o_id);
      var pdfMake = require("../../public/js/pdfmake");

      // var driverOut = this.itemsListDriver.filter(x => x.d_id == this.itemsDataTransport.d_id_in)[0].d_name;
      // var driverIn = this.itemsListDriver.filter(x => x.d_id == this.itemsDataTransport.d_id_out)[0].d_name;

      var q = {};
      q.ts_id = this.idtransport;
      q.retire = 0;
      var passengerInOut = await feathersClientUOA
        .service("viewtransportjob")
        .find({ query: q });

      let headerOut = [
        { text: "No.", bold: true },
        { text: "Passenger Name", bold: true },
        { text: "Company", bold: true },
        { text: "From", bold: true },
        { text: "To", bold: true },
        { text: "Driver", bold: true },
        { text: "Req. plate no.", bold: true },
      ];

      let passengerOutbound = [];
      passengerOutbound.push(headerOut);
      let i = 1;
      passengerInOut.data
        .filter((x) => x.pt_id == 1)
        .forEach((p) => {
          let tel = "";
          if (p.u_telephone) {
            tel = p.u_telephone;
          }
          let data = [];
          data = [
            { text: i, margin: [0, 4, 0, 0] },
            {
              text: p.u_firstname + "  " + p.u_lastname + "\n" + tel,
            },
            { text: p.c_name },
            {
              text:
                p.tsl_picup_name +
                "\n" +
                format(addHours(new Date(p.tsj_picup_time), -7), "HH:mm"),
            },
            {
              text:
                p.tsl_drop_name +
                "\n" +
                format(addHours(new Date(p.tsj_drop_time), -7), "HH:mm"),
            },
            {
              text: p.d_name,
            },
            { text: p.v_license_plate },
          ];
          passengerOutbound.push(data);
          i++;
        });

      if (passengerInOut.data.filter((x) => x.pt_id == 1).length == 0) {
        let noData = [];
        noData = [
          {
            text: "NIL",
            colSpan: 7,
            alignment: "center",
          },
        ];
        passengerOutbound.push(noData);
      }

      let headerIn = [
        { text: "No.", bold: true },
        { text: "Passenger Name", bold: true },
        { text: "Company", bold: true },
        { text: "From", bold: true },
        { text: "To", bold: true },
        { text: "Driver", bold: true },
        { text: "Req. plate no.", bold: true },
      ];

      let passengerInbound = [];
      passengerInbound.push(headerIn);
      let j = 1;
      passengerInOut.data
        .filter((x) => x.pt_id == 2)
        .forEach((p) => {
          let tel = "";
          if (p.u_telephone) {
            tel = p.u_telephone;
          }
          let data = [];
          data = [
            { text: j, margin: [0, 4, 0, 0] },
            {
              text: p.u_firstname + "  " + p.u_lastname + "\n" + tel,
            },
            { text: p.c_name },
            {
              text:
                p.tsl_picup_name +
                "\n" +
                format(addHours(new Date(p.tsj_picup_time), -7), "HH:mm"),
            },
            {
              text:
                p.tsl_drop_name +
                "\n" +
                format(addHours(new Date(p.tsj_drop_time), -7), "HH:mm"),
            },
            {
              text: p.d_name,
            },
            { text: p.v_license_plate },
          ];
          passengerInbound.push(data);
          j++;
        });

      if (passengerInOut.data.filter((x) => x.pt_id == 2).length == 0) {
        let noData = [];
        noData = [
          {
            text: "NIL",
            colSpan: 7,
            alignment: "center",
          },
        ];
        passengerInbound.push(noData);
      }

      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      var transportReport = {
        content: [
          {
            style: "tablehearder",
            table: {
              widths: [100, 310, 100],
              body: [
                [
                  {
                    image: uoaimage.logo,
                    fit: [30, 30],
                    margin: [0, 0, 0, 0],
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "The Report Of Transportation",
                    margin: [0, 8, 0, 0],
                    alignment: "center",
                    border: [false, false, false, false],
                    fontSize: 12,
                    bold: true,
                  },
                  {
                    image: customerlogo.logo,
                    fit: [30, 30],
                    margin: [0, 0, 0, 0],
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
          },
          {
            text: "Date : " + this.transportDate,
            margin: [0, 8, 0, 0],
            alignment: "left",
            bold: true,
          },
          //Out
          {
            text: "Outbound Passengers",
            margin: [0, 8, 0, 0],
            alignment: "left",
            bold: true,
          },
          {
            text: "",
            margin: [0, 8, 0, 0],
          },
          {
            style: "tablehearder",
            table: {
              widths: [13, 120, 65, 70, 70, 60, 50],
              body: passengerOutbound,
            },
          },
          //Out

          {
            text: "",
            margin: [0, 8, 0, 0],
            alignment: "left",
          },

          //In
          {
            text: "Inbound Passengers",
            margin: [0, 8, 0, 0],
            alignment: "left",
            bold: true,
          },
          {
            text: "",
            margin: [0, 8, 0, 0],
          },
          {
            style: "tablehearder",
            table: {
              widths: [13, 120, 65, 70, 70, 60, 50],
              body: passengerInbound,
            },
          },
          //In
        ],
        styles: {
          tablehearder: { fontSize: 8, alignment: "center" },
        },
      };

      //Open preview
      // pdfMake.createPdf(transportReport).open({});

      //Download
      pdfMake.createPdf(transportReport).download("Transport_Report.pdf");
    },
  },
};
</script>

<style></style>